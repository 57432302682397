import React from 'react';
import DIV from '@vl/redata/DIV.macro';
import displayName from '@vl/redata/displayName.macro';
// import _ from 'lodash';

const View8 = ({ children }) => {
  return <DIV className="routeProvider">{children}</DIV>;
};

export default displayName(View8);
