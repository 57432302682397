import React from 'react';
import DIV from '@vl/redata/DIV.macro';
import displayName from '@vl/redata/displayName.macro';
// import _ from 'lodash';
import { ctx } from '@vl/redata';

const View8 = ({ children }) => {
  return <DIV className="component 779BD3C8-CDF6-4067-A5A5-C1B320DFF758">{children}</DIV>;
};

export default displayName(View8);
