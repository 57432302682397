import React from 'react';
import _ from 'lodash';

import useForceUpdate from '@vl/hooks/useForceUpdate';

const isPromise = (obj) => _.isFunction(_.get(obj, 'then'));

export const AsyncRender = (props) => {
  let { render: promise, rendering } = props;
  const ref = React.useRef({
    promise,
  });
  const forceUpdate = useForceUpdate(13);
  let resolvingContent = rendering || null;
  if (_.isFunction(ref.current.promise)) {
    ref.current.promise = promise();
  }
  if (isPromise(ref.current.promise)) {
    ref.current.promise.then((val) => {
      ref.current.promise = val;
      forceUpdate();
    });
    return resolvingContent;
  }
  return ref.current.promise || null;
};

export default AsyncRender;
