import graphme from '@unitz/graphme';

import hasuraClient from '@vl/mod-clients/hasuraApp';

import GetMember from 'unitz-gql/member/GetMember.gql';

graphme.GqlBuilder.loadDocument({
  GetMember,
});

class MemberModel extends graphme.BaseModel {
  static DEFINITION = graphme.Definition.create({
    name: 'Member',
    schema: {
      id: String,
    },
    nodes: [],
    key: 'id',

    baseQuery: 'GetMember',

    GQL_ACTIONS: {
      FIND: 'b2b_member',
      GET: 'b2b_member_by_pk',
      INSERT: 'insert_b2b_member_one',
      UPDATE: 'update_b2b_member_by_pk',
    },

    getClient: hasuraClient.getClient,
  });

  static getClient(...args) {
    return hasuraClient.getClient(...args);
  }

  static async updateMemberProfile({ user_id, account_id, id, set_profile, roles }) {
    const query = hasuraClient.gql`
      mutation updateMember($account_id: uuid!, $user_id: String!, $id: uuid!, $set_profile: b2b_member_profile_set_input = {}, $roles: [b2b_member_role_insert_input!]!) {
        profile: update_b2b_member_profile_by_pk(pk_columns: {id: $id}, _set: $set_profile) {
          id
        }
        delete_b2b_member_role(where: {account_id: {_eq: $account_id}, user_id: {_eq: $user_id}}) {
          affected_rows
          returning {
            account_id
            role_id
          }
        }
        roles: insert_b2b_member_role(objects: $roles, on_conflict: {constraint: member_role_account_id_user_id_role_id_key, update_columns: role_id}) {
          affected_rows
          returning {
            role_id
            account_id
            user_id
          }
        }
      }
    `;
    try {
      const rtn = await hasuraClient.getClient().request(query, {
        user_id,
        account_id,
        id,
        set_profile,
        roles,
      });

      const data = rtn;
      if (data) {
        return data;
      }
    } catch (err) {
      console.log(err);
    }

    return null;
  }
}

graphme.model({ MemberModel });

export default MemberModel;
