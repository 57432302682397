import React from 'react';
import DIV from '@vl/redata/DIV.macro';
import displayName from '@vl/redata/displayName.macro';
import { ctx } from '@vl/redata';
import CustomCookieConsent from '@uz/unitz-components-web/CustomCookieConsentNew';

const View8 = ({ children }) => {
  return (
    <DIV className="component">
      {/* {ctx.get('authModel.zoomConfig') && (
        <div>Running info {JSON.stringify(ctx.get('authModel.zoomConfig.config'))}</div>
      )} */}
      {children}
      <CustomCookieConsent />
    </DIV>
  );
};

export default displayName(View8);
