const _ = require('lodash');

const getQoSStore = () => {
  const store = {};
  const storeRules = {
    ruleMap: {},
  };
  const refs = {};

  const QoSStore = {
    can(action, target) {
      if (!_.has(store, 'loading')) return true;
      if (_.get(store, 'loading')) {
        throw Error('Can not check QoS while loading');
      }
      if (_.isUndefined(action)) return true;
      if (_.isArray(action)) {
        return _.some(action, (item) => {
          return QoSStore.can(item, target);
        });
      }
      const { ruleMap } = store;
      const rule = _.get(ruleMap, [action]);
      const ruleType = _.get(rule, 'type');
      if (ruleType === 'fixed') {
        const count = _.get(rule, 'count');
        const currentCount = _.get(rule, 'current_count');
        // console.log('qos check', { currentCount, count, action });
        if (currentCount >= count) {
          return false;
        }
      }
      return true;
    },
    getRule(action, target) {
      const { ruleMap } = store;
      const rule = _.get(ruleMap, [action]);
      return rule;
    },
    async checkAccess(action, target) {
      if (!_.has(store, 'loading')) return true;
      if (_.get(store, 'loading')) {
        throw Error('Can not check QoS while loading');
      }
      if (_.isUndefined(action)) return true;
      if (_.isArray(action)) {
        for (const item of action) {
          const checked = await QoSStore.checkAccess(item, target);
          if (checked) {
            return checked;
          }
        }
      }
      if (_.get(store, ['ruleMap', action])) {
        return true;
      }
      const rules = _.get(storeRules, ['ruleMap', action]);
      if (_.get(rules, 'length')) {
        for (const rule of rules) {
          const checked = await rule(action, target);
          if (checked) {
            return checked;
          }
        }
      }
      return false;
    },
    async is(role, target) {
      return true;
    },
    setRules(rules) {
      _.set(store, 'rules', rules);
      const ruleMap = {};
      _.map(rules, (rule) => {
        const key = `${rule.subject}.${rule.action}`;
        const { count } = rule;
        if (!count) return;
        _.set(ruleMap, [key], {
          ...rule,
          ...(_.isUndefined(rule.current_count) ? { current_count: rule.count - 1 } : {}),
        });
      });
      _.set(store, 'ruleMap', ruleMap);
    },
    setLoading(loading) {
      _.set(store, 'loading', loading);
    },
    isLoading() {
      return _.get(store, 'loading');
    },
    addRule: (ruleName, value) => {
      _.update(storeRules.ruleMap, ruleName, (currValue) => {
        currValue = currValue || [];
        currValue.push(value);
        return currValue;
      });
    },
    setRef: (key, val) => {
      return _.set(refs, key, val);
    },
    getRef: (key, val) => {
      return _.get(refs, key, val);
    },
    getUserId: () => {
      const authModel = QoSStore.getRef('authModel');
      if (authModel) {
        return authModel.getUserId();
      }
    },
    extends: (definition) => {
      // TODO: check overwrite
      _.merge(QoSStore, definition);
    },
  };
  return QoSStore;
};

const QoS = getQoSStore();

exports.QoS = QoS;
