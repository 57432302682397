import React from 'react';
import { ctx } from '@vl/redata';
import DIV from '@vl/redata/DIV.macro';
import _ from 'lodash';
import useScript from '@vl/hooks/useScript';
import Helmet from 'react-helmet';
import useRoute from '@vl/hooks/useGbRoute';
import { getCurrentLocaleConfig } from '@uz/mod-translations/utils-biz';
import i18n from 'i18n-js';

export const CustomCookieConsent = () => {
  const route = useRoute();
  const loadedStatus = useScript('https://cdn.jsdelivr.net/gh/orestbida/cookieconsent@v2.8.9/dist/cookieconsent.js');
  const currentLocale = getCurrentLocaleConfig(route);
  const currentLang = currentLocale.get('lang');
  React.useEffect(() => {
    if (loadedStatus === 'ready') {
      const cookieConsent = window.initCookieConsent();

      if (cookieConsent) {
        cookieConsent.run({
          current_lang: currentLang,
          delay: 2000,
          autorun: true,
          autoclear_cookies: true, // default: false
          page_scripts: true, // default: false
          // force_consent: true,
          // delay: 10,
          gui_options: {
            consent_modal: {
              layout: 'cloud',
              position: 'bottom center',
              transition: 'slide',
              swap_buttons: false, // enable to invert buttons
            },
            settings_modal: {
              layout: 'box', // box/bar
              transition: 'slide', // zoom/slide
            },
          },

          onFirstAction: function(user_preferences, cookie) {
            // callback triggered only once
            cookieConsent.updateLanguage(currentLang, true);
          },

          onAccept: function(cookie) {
            // ...
          },

          onChange: function(cookie, changed_preferences) {
            // ...
          },

          languages: {
            de: de,
            en: en,
          },
        });
      }
    }
  }, [loadedStatus, currentLang]);

  const de = {
    consent_modal: {
      title: 'Wir verwenden Cookies!',
      description:
        'Hallo, diese Website verwendet essentielle Cookies, um ihren ordnungsgemäßen Betrieb zu gewährleisten, und Tracking-Cookies, um zu verstehen, wie Sie damit interagieren. Letztere werden erst nach Zustimmung gesetzt. <button type="button" data-cc="c-settings" class="cc-link">Lass mich wählen</button>',
      primary_btn: {
        text: 'Akzeptiere alle',
        role: 'accept_all', // 'accept_selected' or 'accept_all'
      },
      secondary_btn: {
        text: 'Alles ablehnen',
        role: 'accept_necessary', // 'settings' or 'accept_necessary'
      },
    },
    settings_modal: {
      title: 'Cookie-Einstellungen',
      save_settings_btn: 'Einstellungen speichern',
      accept_all_btn: 'Akzeptiere alle',
      reject_all_btn: 'Alles ablehnen',
      close_btn_label: 'Schließen',
      cookie_table_headers: [{ col1: 'Name' }, { col2: 'Domain' }, { col3: 'Ablauf' }, { col4: 'Beschreibung' }],
      blocks: [
        {
          title: 'Cookie-Nutzung 📢',
          description:
            'Ich verwende Cookies, um die grundlegenden Funktionen der Website sicherzustellen und Ihr Online-Erlebnis zu verbessern. Sie können für jede Kategorie wählen, ob Sie sich jederzeit an- oder abmelden möchten. Weitere Einzelheiten zu Cookies und anderen sensiblen Daten finden Sie in der vollständigen <a href="#" class="cc-link">Datenschutzerklärung</a>.',
        },
        {
          title: 'Unbedingt erforderliche Cookies',
          description:
            'Diese Cookies sind für das reibungslose Funktionieren meiner Website unerlässlich. Ohne diese Cookies würde die Website nicht richtig funktionieren',
          toggle: {
            value: 'necessary',
            enabled: true,
            readonly: true, // cookie categories with readonly=true are all treated as "necessary cookies"
          },
        },
        {
          title: 'Leistungs- und Analyse-Cookies',
          description: 'Diese Cookies ermöglichen es der Website, sich an die Entscheidungen zu erinnern, die Sie in der Vergangenheit getroffen haben',
          toggle: {
            value: 'analytics', // your cookie category
            enabled: false,
            readonly: false,
          },
          cookie_table: [
            // list of all expected cookies
            {
              col1: '^_ga', // match all cookies starting with "_ga"
              col2: 'google.com',
              col3: '2 years',
              col4: 'description ...',
              is_regex: true,
            },
            {
              col1: '_gid',
              col2: 'google.com',
              col3: '1 day',
              col4: 'description ...',
            },
          ],
        },
        {
          title: 'Werbe- und Targeting-Cookies',
          description:
            'Diese Cookies sammeln Informationen darüber, wie Sie die Website nutzen, welche Seiten Sie besucht und auf welche Links Sie geklickt haben. Alle Daten sind anonymisiert und können nicht dazu verwendet werden, Sie zu identifizieren',
          toggle: {
            value: 'targeting',
            enabled: false,
            readonly: false,
          },
        },
        {
          title: 'Mehr Informationen',
          description:
            'Bei Fragen zu unserer Richtlinie zu Cookies und Ihren Auswahlmöglichkeiten <a class="cc-link" href="https://unitz.biz">kontaktieren Sie uns bitte</a>.',
        },
      ],
    },
  };
  const en = {
    consent_modal: {
      title: 'We use Cookies!',
      description:
        'Hi, this website uses essential cookies to ensure its proper operation and tracking cookies to understand how you interact with it. The latter will be set only after consent. <button type="button" data-cc="c-settings" class="cc-link">Let me choose</button>',
      primary_btn: {
        text: 'Accept all',
        role: 'accept_all', // 'accept_selected' or 'accept_all'
      },
      secondary_btn: {
        text: 'Reject all',
        role: 'accept_necessary', // 'settings' or 'accept_necessary'
      },
    },
    settings_modal: {
      title: 'Cookie preferences',
      save_settings_btn: 'Save settings',
      accept_all_btn: 'Accept all',
      reject_all_btn: 'Reject all',
      close_btn_label: 'Close',
      cookie_table_headers: [{ col1: 'Name' }, { col2: 'Domain' }, { col3: 'Expiration' }, { col4: 'Description' }],
      blocks: [
        {
          title: 'Cookie usage 📢',
          description:
            'I use cookies to ensure the basic functionalities of the website and to enhance your online experience. You can choose for each category to opt-in/out whenever you want. For more details relative to cookies and other sensitive data, please read the full <a href="#" class="cc-link">privacy policy</a>.',
        },
        {
          title: 'Strictly necessary cookies',
          description:
            'These cookies are essential for the proper functioning of my website. Without these cookies, the website would not work properly',
          toggle: {
            value: 'necessary',
            enabled: true,
            readonly: true, // cookie categories with readonly=true are all treated as "necessary cookies"
          },
        },
        {
          title: 'Performance and Analytics cookies',
          description: 'These cookies allow the website to remember the choices you have made in the past',
          toggle: {
            value: 'analytics', // your cookie category
            enabled: false,
            readonly: false,
          },
          cookie_table: [
            // list of all expected cookies
            {
              col1: '^_ga', // match all cookies starting with "_ga"
              col2: 'google.com',
              col3: '2 years',
              col4: 'description ...',
              is_regex: true,
            },
            {
              col1: '_gid',
              col2: 'google.com',
              col3: '1 day',
              col4: 'description ...',
            },
          ],
        },
        {
          title: 'Advertisement and Targeting cookies',
          description:
            'These cookies collect information about how you use the website, which pages you visited and which links you clicked on. All of the data is anonymized and cannot be used to identify you',
          toggle: {
            value: 'targeting',
            enabled: false,
            readonly: false,
          },
        },
        {
          title: 'More information',
          description:
            'For any queries in relation to our policy on cookies and your choices, please <a class="cc-link" href="#yourcontactpage">contact us</a>.',
        },
      ],
    },
  };
  if (loadedStatus !== 'ready') {
    return null;
  }
  return (
    <DIV>
      <Helmet>
        <link
          rel="stylesheet"
          type="text/css"
          href="https://cdn.jsdelivr.net/gh/orestbida/cookieconsent@v2.8.9/dist/cookieconsent.css"
        />
      </Helmet>
    </DIV>
  );
};

export default CustomCookieConsent;
