const _ = require('lodash');

const requireFromString = _.memoize((str) => {
  let rtn;
  try {
    const retifySrc = `return (function() {
      var exports = {};
      var module = { exports: exports };
      ${str}
    return module.exports;})();`;
    const retify = Function(retifySrc);
    rtn = retify();
  } catch (err) {
    console.log(err);
  }
  return rtn;
});

exports.requireFromString = requireFromString;
