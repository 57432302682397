import React from 'react';

import DIV from '@vl/redata/DIV.macro';
import { ctx } from '@vl/redata';
import { Image } from '@uz/unitz-components-web/Image';
import { Row, Col } from 'antd';
import Button from '@uz/unitz-components-web/Button';
import { Link } from '@uz/unitz-components-web/Link';
import _ from 'lodash';
import { ResponsiveProps } from '@uz/unitz-components-web/Responsive';
import styled from 'styled-components';
import gstyles from '@vl/gstyles';
import i18n from 'i18n-js';

const CustomText = styled.div`
  div {
    p {
      margin-bottom: 0;
      color: ${gstyles.colors.sub};
    }
  }
`;

const PageNotFound = () => {
  const locale = i18n.locale;
  return (
    <DIV className="pageContainer">
      <div style={{ height: '100vh' }}>
        <div className="h-full content flex flex-col justify-center items-center">
          <div className="mb-6">
            <ResponsiveProps
              md={{ ratio: 0.5, width: 768, height: 768 * 0.5 }}
              xs={{ ratio: 0.6, width: 400, height: 420 * 0.6 }}
              lg={() => ({ ratio: 0.4, width: 1200, height: 800 * 0.4 })}
            >
              {(resProps) => (
                <Image
                  size="cover"
                  src="https://images.ctfassets.net/4cwknz5arvmg/12YLSosTFAyJZYTGiYkSEp/13feb50563022ed524e5d0669b2e9d1a/404-01_2.png"
                  className="hero-image"
                  alt="banner"
                  {...resProps}
                />
              )}
            </ResponsiveProps>
          </div>

          <div className="text-center">
            <h1 style={{ color: gstyles.colors.brandb.darker }} className="text-2xl mb-4 lg:text-5xl font-semibold">
              {ctx.apply('i18n.t', 'Content404.message')}
            </h1>
            <div className="mb-8">
              <CustomText>
                <div className="text-sm lg:text-xl mb-0">{ctx.apply('i18n.t', 'Content404.description')}</div>
                <div className="text-sm lg:text-xl mb-0">{ctx.apply('i18n.t', 'Content404.requirement')}</div>
              </CustomText>
            </div>
            <Row className="justify-center">
              <Col xs={12} md={16} lg={16} xl={16}>
                <Link to={`/${locale}/`}>
                  <ResponsiveProps xs={{ size: 'medium' }} md={{ size: 'medium' }} lg={{ size: 'large' }}>
                    {(resProps) => (
                      <Button className="w-full" type="primary" {...resProps}>
                        {ctx.apply('i18n.t', 'Content404.goHome')}
                      </Button>
                    )}
                  </ResponsiveProps>
                </Link>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </DIV>
  );
};

export default PageNotFound;
