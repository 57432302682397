import _ from 'lodash';
import importAll from '@vl/mod-utils/import-all.macro';
import path from 'path';
import env from '@vl/mod-utils/penv.macro';
import remoteconfigClient from '@vl/mod-clients/remoteconfigApp';

import i18n from '../web';
import { loader } from './index';

const mod = env({
  development: (() => {
    const modules = importAll.sync('../lang/*.js');
    _.map(modules, (mod, key) => {
      const lang = path.basename(key, '.js');
      loader.set(lang, mod);
      i18n.translations[lang] = mod;
    });
    return modules;
  })(),
  production: (() => {
    // const modules = importAll.sync('../lang/*.js');
    // _.map(modules, (mod, key) => {
    //   const lang = path.basename(key, '.js');
    //   loader.set(lang, mod);
    //   i18n.translations[lang] = mod;
    // });
    // return modules;
  })(),
});

mod;

_.extend(loader, {
  load: async (lang) => {
    const version = await remoteconfigClient.getClient().version('modules/mod-translations');
    const translation = await remoteconfigClient.getClient().require(`lang/${lang}.js?v=${version}`, {
      repo: 'mod-translations',
      withoutLocale: true,
    });
    return translation;
  },
  set: (lang, content) => {
    _.set(loader.getStore(), [lang], content);
    i18n.translations[lang] = content;
  },
});

_.extend(i18n, {
  loadTranslation: (lang) => {
    lang = lang || i18n.locale;
    loader.get(lang);
    return loader.get(lang);
  },
});
