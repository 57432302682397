const _ = require('lodash');
// const fetch = require('node-fetch');
const modConfig = require('@vl/mod-config');
const path = require('path');
const { requireFromString } = require('@vl/mod-utils/requireFromString');

const getOptions = _.memoize((opts) => {
  const options = {
    origin: modConfig.get('REMOTE_CONFIG_ORIGIN') || 'https://zoom.unitz.app',
    LOCALES: ['en', 'vi', 'de'],
    FULL_LOCALES_MAPPING: {
      en: 'en_US',
      vi: 'vi_VN',
      de: 'de_DE',
    },
    ...opts,
    // debug: true,
  };
  return options;
});

const getOrigin = () => {
  const origin = _.get(window, 'location.origin');
  if (_.startsWith(origin, 'http://localhost')) {
    return getOptions().origin;
  }
  return '';
};

const getLocalePrefix = () => {
  const pathname = _.get(window, 'location.pathname');
  const paths = _.split(_.trim(pathname, '/'), '/');
  const locale = _.first(paths);
  if (getOptions().LOCALES.includes(locale)) {
    return `${locale}`;
  }
  return '';
};

const suffixFilenameWithLocale = (filename) => {
  const parts = _.split(filename, '/');
  const extname = path.extname(filename);
  const basename = path.basename(filename, extname);
  const locale = getLocalePrefix();
  const localeSuffix = _.get(getOptions().FULL_LOCALES_MAPPING, locale) || '';
  if (localeSuffix && parts.length) {
    const suffixBaseName = `${basename}_${localeSuffix}${extname}`;
    parts[parts.length - 1] = suffixBaseName;
    return parts.join('/');
  }
  return filename;
};

const _cacheVersion = {};

exports.getClient = _.memoize(() => {
  const client = {
    version: async (filename) => {
      if (!_.has(_cacheVersion, [filename])) {
        let ver = Date.now();
        try {
          const ver_url = `https://unitz-version.web.app/${filename}/v.txt`;
          const response = await fetch(ver_url);
          ver = await response.text();
        } catch (err) {}
        _.set(_cacheVersion, [filename], ver);
      }
      return _.get(_cacheVersion, [filename]);
    },
    require: async (filename, opts) => {
      let response;
      const repo = _.get(opts, 'repo', 'remote-config');
      let pathname = `/files/${repo}/${_.trim(filename, '/')}`;
      pathname = _.get(opts, 'withoutLocale') ? pathname : suffixFilenameWithLocale(pathname);
      const file_url = `${getOrigin()}${pathname}`;

      console.log(file_url);
      try {
        response = response || (await fetch(file_url));
        const [ext] = path.extname(filename).split('?');
        if (ext === '.js') {
          const body = await response.text();
          const rtn = requireFromString(body);
          return rtn;
        }
        if (ext === '.json') {
          const body = await response.text();
          const rtn = JSON.parse(body);
          return rtn;
        }
        const body = await response.text();
        return body;
      } catch (err) {
        console.log(err);
      }
    },
  };
  return client;
});
