import React from 'react';
import DIV from '@vl/redata/DIV.macro';
import displayName from '@vl/redata/displayName.macro';
import { ctx } from '@vl/redata';
import PageNotFound from '@uz/unitz-tool-pages/PageNotFound';
import AsyncRender from '@uz/unitz-components-web/AsyncRender';

const View8 = ({ children }) => {
  return (
    <DIV className="component 779BD3C8-CDF6-4067-A5A5-C1B320DFF758">
      {(() => {
        if (ctx.get('accountModel.isLoading')) return null;
        return (
          <AsyncRender render={async () => {
            const checkAccess = await ctx.apply('accountModel.checkAccess');
            if (!checkAccess) return <PageNotFound />;
            return children;
          }}/>
        );
      })()}
    </DIV>
  );
};

export default displayName(View8);
