import React from 'react';
import { bindings, hook } from '@vl/redata';
import _ from 'lodash';
import MemberModel from '@uz/unitz-models/B2BMemberModel';
import UserModel from '@uz/unitz-models/UserModel';
import useObservableSource from '@vl/hooks/useObservableSource';
import withLoadingPromise from '@vl/hooks/withLoadingPromise';
import i18n from 'i18n-js';
import firebase from 'gatsby-plugin-firebase';
import { useLocalStorage } from '@vl/hooks/useLocalStorageWeb';
import { loader } from '@uz/mod-translations/loader';
import moment from 'moment';
import 'moment-timezone';

const bindData = bindings({
  component: {
    rules: [
      [
        'data',
        {
          data: {
            currentUserModel: hook((ctx) => {
              const currentUser = ctx.get('authModel.currentUser', {});
              const account_id = ctx.apply('accountModel.getAccountId');
              const [userProfile, $userProfile] = React.useState(null);
              const [activeLanguage, $activeLanguage] = React.useState('');
              const [timezone, $timezone] = useLocalStorage('@NA::tz', '');
              const userId = _.get(currentUser, 'uid');
              const ref = React.useRef({});
              _.assign(ref.current, {
                currentUser,
                userProfile,
                $userProfile,
                timezone,
                $timezone,
                userId,
                activeLanguage,
                $activeLanguage,
                ...withLoadingPromise('inited'),
              });

              const memberSubscribe = useObservableSource(() => {
                if (!account_id || !userId) return null;
                return MemberModel.find(
                  `where: {
                      account_id: {_eq: "${account_id}"}
                      user_id: {_eq: "${userId}"}
                    }`,
                  `profile: member_profile {
                      first_name
                      last_name
                      email
                      phone
                      avatar_url
                    }`
                );
              }, [account_id, userId]);
              const userSubscribe = useObservableSource(() => {
                if (!userId) return null;
                return UserModel.find(
                  `where: {id: {_eq: "${userId}"}}`,
                  `profile {
                      display_name
                      phone_number
                    }`
                );
              }, userId);

              React.useEffect(() => {
                const subsMember = memberSubscribe.subscribe((item) => {
                  const profile = _.get(item, '0.profile', {});
                  if (!_.isEqual(ref.current.userProfile, profile) && profile) {
                    ref.current.$userProfile && ref.current.$userProfile(profile);
                  }
                  if (_.keys(profile).length) {
                    ref.current.inited.resolve(true);
                  }
                });
                const subs = userSubscribe.subscribe((item) => {
                  const profile = _.get(item, '0.profile', {});
                  if (!_.isEqual(ref.current.userProfile, profile) && profile && !ref.current.userProfile) {
                    ref.current.$userProfile && ref.current.$userProfile(profile);
                  }
                  if (_.keys(profile).length) {
                    ref.current.inited.resolve(true);
                  }
                });
                return () => {
                  subs.unsubscribe();
                  subsMember.unsubscribe();
                };
                // eslint-disable-next-line
              }, [userId]);

              React.useEffect(() => () => (ref.current = {}), []);
              const currentUserModel = {
                currentUser,
                timezone,
                activeLanguage: ref.current.activeLanguage,
                updateLanguage: async (lang) => {
                  await loader.get(lang);
                  ref.current.$activeLanguage(lang);
                  if (userId && lang) {
                    await firebase
                      .firestore()
                      .collection('appconfigs')
                      .doc(userId)
                      .set(
                        {
                          lang,
                        },
                        { merge: true }
                      );
                  }
                  i18n.locale = lang;
                  localStorage.setItem('lang', lang);
                },
                getUserId: () => {
                  return _.get(ref.current.currentUser, 'uid');
                },
                getDisplayName: (def) => {
                  if (!_.get(ref.current.userProfile, 'first_name')) {
                    return _.get(ref.current.currentUser, 'displayName', '');
                  }
                  return (
                    _.get(ref.current.userProfile, 'first_name', def || '') +
                    ' ' +
                    _.get(ref.current.userProfile, 'last_name', def || '')
                  );
                },
                getPhoneNumber: (def) => {
                  return _.get(ref.current.userProfile, 'phone_number', def || '');
                },
                getAvatarUrl: () => {
                  if (!_.get(ref.current.userProfile, 'avatar_url', '')) {
                    return _.get(ref.current.currentUser, 'photoURL', '');
                  }
                  return _.get(ref.current.userProfile, 'avatar_url', '');
                },
                getLang: async () => {
                  let lang;
                  try {
                    if (userId) {
                      const docRef = firebase
                        .firestore()
                        .collection('appconfigs')
                        .doc(userId);
                      let docData = await docRef.get();
                      docData = docData && docData.data();
                      lang = _.get(docData, 'lang');
                    }
                  } catch (err) {}
                  return lang;
                },
                guessTz: async () => {
                  let tz;
                  try {
                    tz = Intl.DateTimeFormat().resolvedOptions().timeZone;
                    if (userId) {
                      const docRef = firebase
                        .firestore()
                        .collection('users')
                        .doc(userId);
                      let docData = await docRef.get();
                      docData = docData && docData.data();
                      const currentTz = _.get(docData, 'tz');
                      console.log({ currentTz });
                      $timezone(currentTz);

                      // auto update
                      if (!currentTz && tz) {
                        await currentUserModel.updateTz(tz);
                      }
                      if (!!timezone) {
                        console.log('has tz....');
                        await moment.tz.setDefault(timezone);
                      }
                    }
                  } catch (err) {}
                  return tz;
                },
                getTz: async () => {
                  let tz;
                  try {
                    if (userId) {
                      const docRef = firebase
                        .firestore()
                        .collection('users')
                        .doc(userId);
                      let docData = await docRef.get();
                      docData = docData && docData.data();
                      tz = _.get(docData, 'tz');
                    }
                  } catch (err) {}
                  return tz;
                },
                updateTz: async (tz) => {
                  if (userId && tz) {
                    await firebase
                      .firestore()
                      .collection('users')
                      .doc(userId)
                      .set({ tz }, { merge: true });
                  }
                },
                checkReady: () => {
                  return ref.current.inited.check();
                },
              };
              React.useEffect(() => {
                userId && currentUserModel.guessTz();
              }, [userId]);
              ctx.apply('REF.setRef', 'currentUserModel', currentUserModel);
              return currentUserModel;
            }),
          },
        },
      ],
    ],
  },
});
export default bindData;
